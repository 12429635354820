import { useEffect } from "react";
import { Hub } from "aws-amplify";

export function useAuthEvents(callback: (event: string, data: { message: string }) => void) {
  useEffect(() => {
    return Hub.listen("auth", ({ payload: { event, data } }) => {
      callback(event, data as { message: string });
    });
  }, [callback]);
}
