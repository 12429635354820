import { Amplify, Auth, Hub } from "aws-amplify";
import type { HubCapsule } from "@aws-amplify/core";
import { CognitoUser } from "amazon-cognito-identity-js";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { DConfOutput } from "dconf-service/src/types";

import { getGivenNameFromCognito, getUserNameFromCognito, getGroupsFromCognito } from "./utils";

interface AuthState {
  userId: string | null;
  userName: string | null;
  givenName: string | null;
  cognitoGroups: string[] | null;
  isAuthenticated: boolean | null;
  isLoading: boolean;
  errorMessage: string | null;
  init: (domainConfig: DConfOutput) => void;
  checkSession: () => Promise<boolean>;
  federatedSignIn: (customProvider: string) => Promise<unknown>;
  signIn: (username: string, password: string) => Promise<boolean>;
  signOut: () => void;
}

export const useAuthStore = create<AuthState>()(
  devtools(
    immer((set) => {
      const updateUser = (user: CognitoUser | null) => {
        set((state) => {
          if (!user) {
            state.isAuthenticated = false;
            state.userId = null;
            state.userName = null;
            state.givenName = null;
            state.cognitoGroups = null;

            return;
          }

          state.isAuthenticated = true;
          state.userId = user.getUsername();
          state.userName = getUserNameFromCognito(user);
          state.givenName = getGivenNameFromCognito(user);
          state.cognitoGroups = getGroupsFromCognito(user);
        });

        return;
      };

      const setLoading = (loading: boolean) =>
        set((state) => {
          state.isLoading = loading;
        });

      const setErrorMessage = (message: string | null) =>
        set((state) => {
          state.errorMessage = message;
        });

      const setSuccess = () => {
        setLoading(false);
        setErrorMessage(null);
      };

      const setFailure = (message: string | null) => {
        setLoading(false);
        setErrorMessage(message);
      };

      async function eventListener({ payload: { event, data } }: HubCapsule) {
        switch (event) {
          case "signIn":
          case "cognitoHostedUI":
            updateUser(await Auth.currentAuthenticatedUser() as CognitoUser);
            setSuccess();

            break;

          case "signOut":
            updateUser(null);
            setSuccess();

            break;

          case "signIn_failure":
          case "cognitoHostedUI_failure": {
            const dataMessage = (data as { message: string })?.message;

            if (dataMessage === "User+is+not+assigned+to+the+client+application.%3B+error%3Daccess_denied") {
              console.error("You need the tile to be assigned!");
            }

            setFailure(dataMessage);

            break;
          }

          case "autoSignIn":
            updateUser(await Auth.currentAuthenticatedUser() as CognitoUser);
            setSuccess();

            break;

          case "tokenRefresh":
            updateUser(await Auth.currentAuthenticatedUser() as CognitoUser);
            setSuccess();

            break;

          case "tokenRefresh_failure": {
            const dataMessage = (data as { message: string })?.message;

            setFailure(dataMessage);
            updateUser(null);

            break;
          }
        }
      }

      return {
        userId: null,
        userName: null,
        givenName: null,
        cognitoGroups: null,
        isAuthenticated: null,
        isLoading: false,
        errorMessage: null,

        init: (domainConfig: DConfOutput) => {
          Amplify.configure(domainConfig.auth.cognito);
          Hub.listen("auth", eventListener);
        },

        checkSession: async () => {
          setLoading(true);

          try {
            await Auth.currentSession();
            const user = await Auth.currentAuthenticatedUser() as CognitoUser;

            updateUser(user);

            return true;
          } catch {
            updateUser(null);

            return false;
          } finally {
            setLoading(false);
          }
        },

        federatedSignIn: async (customProvider = "ICDev") => {
          setLoading(true);

          try {
            return await Auth.federatedSignIn({ customProvider });
          } catch (error) {
            console.error("federate login error", error);

            throw error;
          } finally {
            setLoading(false);
          }
        },

        signIn: async (username: string, password: string) => {
          try {
            await Auth.signIn(username, password);

            return true;
          } catch {
            return false;
          }
        },

        signOut: async () => {
          await Auth.signOut();
        },
      };
    })
  )
);

const { init, checkSession, federatedSignIn, signIn, signOut } = useAuthStore.getState();

export const authActions = {
  init,
  checkSession,
  federatedSignIn,
  signIn,
  signOut,
};
