import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Flex, Spin } from "antd";

import MainPageContent from "../../../components/Layout/MainPageContent";
import { authActions, useAuthStore } from "../../../store/auth/auth.store";
import { useDconfStore } from "../../../store/dconf/dconf.store";
import { ACCESS_DENIED, PROVIDER_ACCESS_DENIED } from "../../../store/auth/auth.constants";
import { useAuthEvents } from "../../../store/auth/useAuthEvents";

import "./LoginPage.scss";

const LoginPage = () => {
  const { federatedSignIn } = authActions;
  const [isAuthLoading, isAuthenticated, errorMessage] = useAuthStore((state) => [
    state.isLoading,
    state.isAuthenticated,
    state.errorMessage,
  ]);

  const dconf = useDconfStore((state) => state.dconf);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const issuer = searchParams.get("iss");

  const isRedirect = searchParams.has("code");
  const isErrorRedirect = searchParams.has("error");

  const isLoading = isAuthLoading || isRedirect;

  const federatedSignInConfigs = useMemo(() => dconf?.auth.federatedSignIn ?? [], [dconf]);

  useEffect(() => {
    if (issuer) {
      const customConf = federatedSignInConfigs.find((config) => issuer === config.issuer);

      if (customConf) {
        // TODO: make the Error handling for federatedSignIn
        federatedSignIn(customConf.customProvider).catch((error) => console.error("federate login error", error));
      }
    } else if (isAuthenticated === null) {
      authActions.checkSession().catch((error) => console.error(error));
    } else if (isAuthenticated === true) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const origin = location.state?.from?.pathname as string || "/";

      navigate(origin);
    }
  }, [federatedSignInConfigs, federatedSignIn, isAuthenticated, issuer, location, navigate]);

  /**
   * Could handle all error cases
   * */
  useEffect(() => {
    if (isErrorRedirect) {
      const error = searchParams.get("error");

      switch (error) {
        case ACCESS_DENIED: {
          navigate("/access-denied");
          break;
        }

        default: {
          console.error(error);
        }
      }
    }
  }, [searchParams, isErrorRedirect, navigate]);

  /**
   * Handles situation when we don't get an events from Auth as it's too late
   * */
  useEffect(() => {
    if (errorMessage === PROVIDER_ACCESS_DENIED || errorMessage?.includes(ACCESS_DENIED)) {
      navigate("/access-denied");
    }
  }, [navigate, errorMessage]);

  /**
   * Handles situation when user has access to wpp but don't have access to the app
   * */
  useAuthEvents((event, data) => {
    const message = data?.message;

    if (
      ["signIn_failure", "cognitoHostedUI_failure", "customState_failure"].includes(event)
      && (message === PROVIDER_ACCESS_DENIED || message.includes(ACCESS_DENIED))
    ) {
      navigate("/access-denied");
    }
  });

  return (
    <MainPageContent className="centered-box">
      <Flex vertical={true} gap="middle">
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <>
            {federatedSignInConfigs.map(({ name, customProvider }) => {
              return (
                <Button
                  type={customProvider === "QABot" ? "text" : "primary"}
                  size="large"
                  className="login-page__federated-login-card"
                  key={name}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => void federatedSignIn(customProvider)}
                  data-testid={`login-page__federated-login-card-${name}`}
                >
                  Sign in with {name}
                </Button>
              );
            })}
          </>
        )}
      </Flex>
    </MainPageContent>
  );
};

export default LoginPage;
