import { Auth } from "aws-amplify";

export async function getAuthHeader() {
  const currentSession = await Auth.currentSession().catch(() => null);

  if (!currentSession) {
    return { Authorization: "" };
  }

  const idToken = currentSession.getIdToken();
  const jwt = idToken.getJwtToken();

  return { Authorization: jwt || "" };
}
