import { CreativeIntelligenceMetric } from "../pages/DashboardPage/queries/utils";

import { DiscardMetricThreshold, TokenPagination } from "./creativeInteligence";
import { DateRange, ReportBreakdown } from "./index";

export enum SelfServiceExtractor {
  EXTFB_INVENTORY = "EXTFB_INVENTORY",
  EXTDCM_INVENTORY = "EXTDCM_INVENTORY",
  EXTDV360_INVENTORY = "EXTDV360_INVENTORY",
  EXTTIKTOK_INVENTORY = "EXTTIKTOK_INVENTORY",
}

export const resourceTypeToExtractorType = {
  [SelfServiceExtractor.EXTFB_INVENTORY]: "facebook",
  [SelfServiceExtractor.EXTDCM_INVENTORY]: "dcm",
  [SelfServiceExtractor.EXTDV360_INVENTORY]: "dv360",
  [SelfServiceExtractor.EXTTIKTOK_INVENTORY]: "tiktok",
} as const;

interface StorageInfo {
  s3Prefix: string;
  s3Bucket: string;
}

interface TemporaryFileLocation {
  bucket: string;
  key: string;
}

export interface FileLocation {
  bucket: string;
  key: string;
  signedUrl: string;
}

interface Entity {
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  id: string;
}

export interface User {
  name: string;
  logoFileLocation?: FileLocation;
}

export interface Brand {
  name: string;
  logoFileLocation?: FileLocation;
}

export type BrandWithId = Brand & { id: string };
export type BrandEntity = Entity & Brand;

export interface UpdateBrand {
  name: string;
  temporaryLogoFileLocation?: TemporaryFileLocation;
}

export const enum MetricMode {
  Value = "value",
  Average = "avg",
}

export interface MetricSettings {
  metric: CreativeIntelligenceMetric;
  value: number;
  mode: MetricMode;
}

export interface Dashboard {
  name: string;
  confidenceMetric?: MetricSettings;
  discardThresholds?: DiscardMetricThreshold[];
  dynamicBreakdowns?: { [key: string]: string[] };
  kpiMetric?: MetricSettings;
  resourceGroupIds?: string[];
  tagsIds?: string[];
  logoFileLocation?: FileLocation;
  inventoryIds?: string[];
  deliveryIds?: string[];
}

export type DashboardWithId = Dashboard & { id: string };
export type DashboardEntity = Dashboard & Entity;

export interface CreateDashboard {
  name: string;
  brandId: string;
  resourceGroupIds?: string[];
  deliveryIds?: string[];
  inventoryIds?: string[];
  temporaryLogoFileLocation?: TemporaryFileLocation;
  syncData?: {
    source: {
      dashboardId: string;
      stack: string;
      environment: string;
    };
    targetBrandId?: string;
  };
}

export interface UpdateDashboard extends Omit<Dashboard, "name" | "logoFileLocation"> {
  name?: string;
  temporaryLogoFileLocation?: TemporaryFileLocation;
}

export interface ResourceManagerEntity<TOutputSuccess> {
  resourceId: string;
  resourceGroupId: string;
  resourceOutput?: {
    output?: { fail?: string; success?: TOutputSuccess | { [x: string]: unknown } };
    percentage?: number;
  };
}

interface BaseInventoryExtractorInput {
  client?: string;
  resourceGroupId?: string;
  jobName: string;
  storageInfo: StorageInfo;
  minImpressions: number;
  dateRange: DateRange;
}

export type InventoryExtractorInput<T> = T extends SelfServiceExtractor.EXTDCM_INVENTORY
  ? BaseInventoryExtractorInput & {
    profileId: string;
    advertiserIds: string[];
    campaignIds?: string[];
    breakdowns?: ReportBreakdown[];
  } : T extends SelfServiceExtractor.EXTDV360_INVENTORY ? BaseInventoryExtractorInput & {
    advertiserId: string;
    partnerId: string;
  } : T extends SelfServiceExtractor.EXTTIKTOK_INVENTORY ? BaseInventoryExtractorInput & {
    advertiserId: string;
    campaignIds?: string[];
  } : T extends SelfServiceExtractor.EXTFB_INVENTORY ? BaseInventoryExtractorInput & {
    adAccountIds: string[];
    campaignIds?: string[];
    accessToken?: string;
  } : never;

export interface UpdateInventory<T> {
  brandId?: string;
  dashboardId?: string;
  extractorInput: InventoryExtractorInput<T>;
  resourceType: SelfServiceExtractor;
}

interface InventorySuccessOutput {
  inventoryFileLocation: FileLocation;
}

export type Inventory = UpdateInventory<SelfServiceExtractor> & ResourceManagerEntity<InventorySuccessOutput>;
export type InventoryWityId = Inventory & { id: string };
export type InventoryEntity = Inventory & Entity;

export interface DV360ExtractorSettings {
  resourceGroupId?: string;
  metricNames: string[];
  storageInfo: StorageInfo;
}

export interface DCMExtractorSettings extends DV360ExtractorSettings {
  breakdowns: ReportBreakdown[];
}

export interface FBExtractorSettings extends DCMExtractorSettings {
  inventoryInfo: {
    bucket: string;
    key: string;
  };
}

export interface TIKTOKExtractorSettings extends DV360ExtractorSettings {
  breakdowns: ReportBreakdown[];
}

export enum GCVFaceDetectionLikelihood {
  UNKNOWN = "UNKNOWN",
  VERY_UNLIKELY = "VERY_UNLIKELY",
  UNLIKELY = "UNLIKELY",
  POSSIBLE = "POSSIBLE",
  LIKELY = "LIKELY",
  VERY_LIKELY = "VERY_LIKELY",
}

export interface AiServiceThreshold {
  name: string;
  value: string;
}

export const AIServices = {
  GCV: "GCV",
  AWS: "AWS",
  WTBG: "WTBG",
  CLARIFAI: "CLARIFAI",
  CLEANUP: "CLEANUP",
  DRAGONFLY: "DRAGONFLY",
  GOOGLE_NATURAL_LANGUAGE: "GOOGLE_NATURAL_LANGUAGE",
  TEXTURE_AI: "TEXTURE_AI",
  GOOGLE_VIDEO_INTELLIGENCE: "GOOGLE_VIDEO_INTELLIGENCE",
} as const;

export interface DV360Extractor {
  extractorType: "dv360";
  extractor: DV360ExtractorSettings;
}

export interface FacebookExtractor {
  extractorType: "facebook";
  extractor: FBExtractorSettings;
}

export interface DCMExtractor {
  extractorType: "dcm";
  extractor: DCMExtractorSettings;
}

export interface AIConfigs {
  GCV?: {
    COLOUR_SCORE_THRESHOLD: number;
    COLOUR_PIXEL_FRACTION_THRESHOLD: number;
    FACE_CONFIDENCE_THRESHOLD: number;
    FACE_LIKELIHOOD_THRESHOLD: GCVFaceDetectionLikelihood[];
    LABEL_SCORE_THRESHOLD: number;
    LOGO_SCORE_THRESHOLD: number;
    OBJECTS_SCORE_THRESHOLD: number;
    TEXT_DETECTION_THRESHOLD: number;
  };
  AWS?: {
    COMPREHEND_TEXT_ANALYSIS: string[];
    FACE_CONFIDENCE_THRESHOLD: number;
    GENDER_CONFIDENCE_THRESHOLD: number;
    EMOTION_CONFIDENCE_THRESHOLD: number;
    FACIAL_FEATURE_CONFIDENCE_THRESHOLD: number;
    LABEL_CONFIDENCE_THRESHOLD: number;
    CustomLabels: {
      MODELS_ARNS: string[];
    };
    TEXT_ANALYSIS: boolean;
  };
  WTBG?: {
    enabled: boolean;
  };
  CLARIFAI?: {
    enabled: boolean;
  };
  CLEANUP?: {
    GENERAL_BOUNDING_BOX_AREA_THRESHOLD: number;
    GENERAL_AI_RESULT_OCCURRENCES_THRESHOLD: number;
    TEXT_BOUNDING_BOX_INTERSECTION_THRESHOLD: number;
    TEXT_SIMILARITY_THRESHOLD: number;
  };
  DRAGONFLY?: {
    enabled: boolean;
  };
  GOOGLE_NATURAL_LANGUAGE?: {
    enabled: boolean;
  };
  TEXTURE_AI?: {
    enabled: boolean;
  };
  GOOGLE_VIDEO_INTELLIGENCE?: {
    FACE_ATTRIBUTES_THRESHOLD?: number;
    FACE_DETECTION_THRESHOLD?: number;
    TEXT_DETECTION_THRESHOLD?: number;
    LOGO_DETECTION_THRESHOLD?: number;
    PERSON_DETECTION_THRESHOLD?: number;
    OBJECT_TRACKING_THRESHOLD?: number;
    LABEL_DETECTION_THRESHOLD?: number;
    enabled: boolean;
  };
}

export interface DeliveryConfig<
  TExtractor extends
  | DV360ExtractorSettings
  | FBExtractorSettings
  | TIKTOKExtractorSettings
  | DCMExtractorSettings = DV360ExtractorSettings
> {
  inventoryResourceIdUsed: string;
  extractorType: string;
  isAutomatedTest: boolean;
  jobId: string;
  brandId: string;
  inventoryId: string;
  delivery: {
    uploadType: string;
    uploadInfo: {
      gcsPath: string;
      gcsBucket: string;
    };
    normalizationType: string; // default | singapore | "create-lift";
  };
  enrichAIConfig: AIConfigs;
  index: {
    clientId: string;
  };
  extractorInput: TExtractor;
  previousDeliveryId: string;
}

export interface DeliverySuccessOutput {
  normalizeResult: Record<string, unknown>;
  indexResult: {
    esIndexName: string;
  };
  enrichResult: {
    failureReportFileLocation: FileLocation;
    snapshotFileLocation: FileLocation;
  };
  extractorResult: {
    failureReportFileLocation: FileLocation;
    snapshotFileLocation: FileLocation;
  };
}

export type Delivery = DeliveryConfig & ResourceManagerEntity<DeliverySuccessOutput>;
export type DeliveryWithId = Delivery & { id: string };
export type DeliveryEntity = Delivery & Entity;

export interface EntityRelation<ObjectEntity, SubjectEntity> {
  objectEntityListing: ObjectEntity;
  subjectEntityListing: SubjectEntity;
  objectEntityId: string;
  subjectEntityId: string;
  createdAt: string;
  updatedAt: string;
  relationType: string;
  predicateType: string;
}

export interface EntityListResponse<T> {
  items: T[];
  pagination: TokenPagination;
}

export const RELATIONS = {
  DASHBOARD_INVENTORIES: "Inventory_BelongsTo_Dashboard",
  DASHBOARD_DELIVERIES: "Delivery_BelongsTo_Dashboard",
  INVENTORY_DELIVERIES: "Delivery_BelongsTo_Inventory",
  BRAND_DELIVERIES: "Delivery_BelongsTo_Brand",
  BRAND_DASHBOARDS: "Dashboard_BelongsTo_Brand",
  BRAND_INVENTORIES: "Inventory_BelongsTo_Brand",
} as const;
