import { ReportBreakdown } from "../../types";
import {
  AIConfigs,
  AIServices,
  DCMExtractorSettings,
  DeliveryConfig,
  DeliveryEntity,
  FBExtractorSettings,
  FileLocation,
  InventoryWityId,
  SelfServiceExtractor,
  TIKTOKExtractorSettings,
  resourceTypeToExtractorType,
} from "../../types/entities";
import { DomainConfig } from "../../store/config/types";

import { DeliveryJobFormValues } from "./AddDeliveryJob";

export function getDefaultAIConfigForEnabledServices(services: (keyof AIConfigs)[], config: AIConfigs) {
  return services.reduce<AIConfigs>((acc, service) => {
    switch (service) {
      case AIServices.GCV:
        acc.GCV = config.GCV;

        return acc;

      case AIServices.AWS:
        acc.AWS = config.AWS;

        return acc;

      case AIServices.CLEANUP:
        acc.CLEANUP = config.CLEANUP;

        return acc;

      case AIServices.GOOGLE_VIDEO_INTELLIGENCE:
        acc.GOOGLE_VIDEO_INTELLIGENCE = {
          ...config.GOOGLE_VIDEO_INTELLIGENCE,
          enabled: true,
        };

        return acc;

      default:
        acc[service] = { enabled: true };

        return acc;
    }
  }, {});
}

export function getDeliveryConfigFromDelivery({
  previousDeliveryId = "",
  inventoryResourceIdUsed,
  resourceGroupId,
  delivery,
}: {
  previousDeliveryId: string | undefined;
  inventoryResourceIdUsed: string;
  resourceGroupId: string;
  delivery: DeliveryEntity;
}): DeliveryConfig {
  return {
    previousDeliveryId,
    inventoryResourceIdUsed,

    extractorType: delivery.extractorType,
    isAutomatedTest: false,
    index: { clientId: delivery.brandId },
    jobId: delivery.jobId,
    brandId: delivery.brandId,
    inventoryId: delivery.inventoryId,
    extractorInput: { ...delivery.extractorInput, resourceGroupId },
    delivery: {
      ...delivery.delivery,
      uploadInfo: {
        gcsPath: delivery.delivery.uploadInfo.gcsPath,
        gcsBucket: delivery.delivery.uploadInfo.gcsBucket ?? "",
      },
    },
    enrichAIConfig: delivery.enrichAIConfig,
  };
}

export function mapBreakdowns(breakdowns: string[]): ReportBreakdown[] {
  return breakdowns?.map((breakdown) => breakdown.split(", ").map((b) => b.trim())) || [];
}

export function getDeliveryConfigFromForm({
  inventory,
  storageInfo,
  brandId,
  formValues,
  previousDeliveryId,
}: {
  inventory: InventoryWityId;
  brandId: string;
  storageInfo: DomainConfig["storageInfo"];
  formValues: DeliveryJobFormValues;
  previousDeliveryId: string | undefined;
}) {
  const { id, resourceGroupId, resourceType, resourceOutput } = inventory;

  const config: DeliveryConfig = {
    extractorType: resourceTypeToExtractorType[resourceType],
    isAutomatedTest: false,

    index: { clientId: brandId },
    jobId: resourceGroupId,
    brandId,
    inventoryId: id,
    inventoryResourceIdUsed: inventory.resourceId,

    extractorInput: {
      resourceGroupId,
      storageInfo,
      metricNames: formValues.metricNames,
    },

    delivery: {
      uploadType: formValues.uploadType,
      uploadInfo: {
        gcsPath: formValues.gcsPath,
        gcsBucket: formValues.gcsBucket,
      },
      normalizationType: formValues.normalizationType,
    },

    enrichAIConfig: getDefaultAIConfigForEnabledServices(formValues.enrichServices, formValues.enrichAIConfig),

    previousDeliveryId: previousDeliveryId ?? "",
  };

  if (resourceType === SelfServiceExtractor.EXTFB_INVENTORY) {
    const inventoryFileLocation = resourceOutput?.output?.success?.inventoryFileLocation as FileLocation | undefined;

    if (inventoryFileLocation) {
      (config as DeliveryConfig<FBExtractorSettings>).extractorInput.inventoryInfo = {
        key: inventoryFileLocation?.key,
        bucket: inventoryFileLocation?.bucket,
      };
    }

    (config as DeliveryConfig<FBExtractorSettings>).extractorInput.breakdowns = mapBreakdowns(formValues.breakdowns);
  }

  if (resourceType === SelfServiceExtractor.EXTTIKTOK_INVENTORY) {
    (config as DeliveryConfig<TIKTOKExtractorSettings>).extractorInput.breakdowns = mapBreakdowns(
      formValues.breakdowns
    );
  }

  if (resourceType === SelfServiceExtractor.EXTDCM_INVENTORY) {
    (config as DeliveryConfig<DCMExtractorSettings>).extractorInput.breakdowns = mapBreakdowns(formValues.breakdowns);
  }

  return config;
}

export function getInitialFormValues({ config, inventory }: { config: DomainConfig; inventory: InventoryWityId }) {
  const enrichServices = Object.entries(config.enrichAIConfig).reduce<string[]>((acc, [service, aiConfig]) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    if (!Object.hasOwn(aiConfig, "enabled") || aiConfig.enabled) {
      acc.push(service);
    }

    return acc;
  }, []);

  const initialValues = {
    uploadType: "GCS",
    gcsPath: inventory.resourceGroupId,
    gcsBucket: "",
    normalizationType: "default",
    enrichServices,
    enrichAIConfig: config.enrichAIConfig,
  } as DeliveryJobFormValues;

  if (inventory.resourceType === SelfServiceExtractor.EXTFB_INVENTORY) {
    initialValues.metricNames = config.inventoryResources.EXTFB_INVENTORY.metrics.map(({ name }) => name);
    initialValues.breakdowns = config.inventoryResources.EXTFB_INVENTORY.breakdowns.map((breakdown) =>
      breakdown.join(", ")
    );
  }

  if (inventory.resourceType === SelfServiceExtractor.EXTDCM_INVENTORY) {
    initialValues.metricNames = config.inventoryResources.EXTDCM_INVENTORY.metrics.map(({ name }) => name);
    initialValues.breakdowns = config.inventoryResources.EXTDCM_INVENTORY.breakdowns.map((breakdown) =>
      breakdown.join(", ")
    );
  }

  if (inventory.resourceType === SelfServiceExtractor.EXTDV360_INVENTORY) {
    initialValues.metricNames = config.inventoryResources.EXTDV360_INVENTORY.metrics.map(({ name }) => name);
  }

  if (inventory.resourceType === SelfServiceExtractor.EXTTIKTOK_INVENTORY) {
    initialValues.metricNames = config.inventoryResources.EXTTIKTOK_INVENTORY.metrics.map(({ name }) => name);
    initialValues.breakdowns = config.inventoryResources.EXTTIKTOK_INVENTORY.breakdowns.map((breakdown) =>
      breakdown.join(", ")
    );
  }

  return initialValues;
}

export function getMaxDurationForJob(jobType: SelfServiceExtractor): number {
  switch (jobType) {
    case SelfServiceExtractor.EXTFB_INVENTORY:
      return 3;

    case SelfServiceExtractor.EXTTIKTOK_INVENTORY:
      return 1;

    default:
      return 2;
  }
}
