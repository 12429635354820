import { useMemo } from "react";
import { Form, Input, Select } from "antd";

import { DomainConfig } from "../../../store/config/types";

const DeliveryForm = ({ config, show }: { config: DomainConfig; show: boolean }) => {
  const normalizationTypeOptions = useMemo(() => {
    return config.normalizationTypes.map((label) => ({ label, value: label }));
  }, [config.normalizationTypes]);

  return (
    <>
      <Form.Item name="uploadType" label="uploadType" rules={[{ required: false }]} hidden={true}>
        <Input />
      </Form.Item>

      <Form.Item name="gcsPath" label="gcsPath" rules={[{ required: true }]} hidden={true}>
        <Input />
      </Form.Item>

      <Form.Item name="gcsBucket" label="gcsBucket" rules={[{ required: false }]} hidden={true}>
        <Input />
      </Form.Item>

      <Form.Item name="normalizationType" label="normalizationType" rules={[{ required: true }]} hidden={!show}>
        <Select options={normalizationTypeOptions} />
      </Form.Item>
    </>
  );
};

export default DeliveryForm;
