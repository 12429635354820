import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Flex, Form, Input, Modal, Typography, message } from "antd";
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";

import { DashboardWithId } from "../../../types/entities";
import { FileUploader, TemporaryFileLocation } from "../../../components/UI/FileUploader";
import { useDeleteDashboard, useUpdateDashboard } from "../../../queries/entities/dashboard.mutations";
import { useSuccessfulBrandDeliveries } from "../../../queries/entities/brandDeliveries.query";
import { getInventoryAndDeliveryIds, getResourceGroupIds } from "../../../utils/deliveriesUtils";
import { useRouterPaths } from "../../../hooks/useRouterPaths";

import BrandDeliveriesFormSelect from "./BrandDeliveriesFormSelect";

const { Title, Text } = Typography;

interface Props {
  isOpen: boolean;
  brandId: string;
  dashboard: DashboardWithId | undefined;
  onClose: () => void;
  onDelete?: () => void;
}

interface ModalProps extends Props {
  dashboard: DashboardWithId;
}

interface FormData {
  name: string;
  resourceGroupIds: string[];
}

const DashboardModal = ({ isOpen, brandId, dashboard, onClose, onDelete }: ModalProps) => {
  const { brandPath } = useRouterPaths();
  const [form] = Form.useForm<FormData>();

  const successfulBrandDeliveries = useSuccessfulBrandDeliveries(brandId);

  const [temporaryLogoFileLocation, setTemporaryLogoFileLocation] = useState<TemporaryFileLocation | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const updateDashboard = useUpdateDashboard();
  const deleteDashboard = useDeleteDashboard();

  const brandResourceGroupIds = useMemo(() => {
    return getResourceGroupIds(successfulBrandDeliveries.data?.items ?? []);
  }, [successfulBrandDeliveries.data?.items]);

  const initialValues = useMemo(() => {
    return {
      name: dashboard.name,
      resourceGroupIds: dashboard.resourceGroupIds,
    };
  }, [dashboard.name, dashboard.resourceGroupIds]);

  const handleOnSubmit = useCallback(
    async (formData: FormData) => {
      if (!successfulBrandDeliveries.data) {
        message.error({ content: "Failed to load brand deliveries", duration: 3 });

        return;
      }

      try {
        const { deliveryIds, inventoryIds } = getInventoryAndDeliveryIds(
          formData.resourceGroupIds ?? [],
          successfulBrandDeliveries.data.items
        );

        await updateDashboard.mutateAsync({
          brandId,
          dashboardId: dashboard.id,
          dashboardData: {
            name: formData.name,
            resourceGroupIds: formData.resourceGroupIds,
            inventoryIds,
            deliveryIds,
            ...(temporaryLogoFileLocation ? { temporaryLogoFileLocation } : {}),
          },
        });

        message.success({ content: `"${formData.name}" dashboard updated successfully`, duration: 3 });
        onClose();
      } catch {
        message.error({ content: "Something went wrong", duration: 3 });
      }
    },
    [successfulBrandDeliveries.data, brandId, dashboard.id, onClose, temporaryLogoFileLocation, updateDashboard]
  );

  const handleOnDelete = useCallback(() => setIsConfirmDeleteOpen(true), []);
  const handleOnDeleteConfirmCancel = useCallback(() => setIsConfirmDeleteOpen(false), []);

  const handleOnDeleteConfirm = useCallback(async () => {
    setIsConfirmDeleteOpen(false);

    try {
      await deleteDashboard.mutateAsync({ brandId, dashboardId: dashboard.id });

      message.success({ content: `"${dashboard.name}" dashboard deleted successfully`, duration: 3 });

      onDelete?.();
      onClose();
    } catch {
      message.error({ content: "Something went wrong", duration: 3 });
    }
  }, [brandId, dashboard.id, dashboard.name, deleteDashboard, onClose, onDelete]);

  const modalFooter = [
    <Button
      key="delete"
      danger
      icon={<DeleteOutlined />}
      onClick={handleOnDelete}
      loading={deleteDashboard.isPending}
      data-testid="edit-dashboard-modal-delete-button"
    >
      Delete Dashboard
    </Button>,

    <Button key="cancel" onClick={onClose} data-testid="edit-dashboard-modal-cancel-button">
      Cancel
    </Button>,

    <Button
      key="confirm"
      type="primary"
      onClick={form.submit}
      loading={updateDashboard.isPending}
      disabled={isUploading}
      data-testid="edit-dashboard-modal-confirm-button"
    >
      Confirm
    </Button>,
  ];

  return (
    <>
      <Modal
        title={<Title level={4}>{`Edit "${dashboard.name}"`}</Title>}
        open={isOpen}
        footer={modalFooter}
        onCancel={onClose}
        cancelButtonProps={{ ["data-testid"]: "edit-dashboard-modal-close-button" }}
      >
        <Form form={form} layout="vertical" initialValues={initialValues} onFinish={handleOnSubmit}>
          <Form.Item label="Dashboard name" name="name" rules={[{ required: true }]}>
            <Input data-testid="create-dashboard-modal-name-input" />
          </Form.Item>

          <BrandDeliveriesFormSelect
            brandResourceGroupIds={brandResourceGroupIds}
            loading={successfulBrandDeliveries.isPending}
          />

          <Flex justify="flex-end">
            <Link to={`${brandPath}/inventories?dashboardId=${dashboard.id}`}>
              <Text>
                View dashboard inventories <ArrowRightOutlined />
              </Text>
            </Link>
          </Flex>

          <Form.Item label="Logo" valuePropName="fileList">
            <FileUploader
              testid="dashboard-logo-upload"
              logoUrl={dashboard.logoFileLocation?.signedUrl}
              onSuccess={setTemporaryLogoFileLocation}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
          </Form.Item>
        </Form>

        <Divider />
      </Modal>

      <Modal
        title={`Delete dashboard ${dashboard.name}?`}
        width={400}
        open={isConfirmDeleteOpen}
        okText="Delete"
        okButtonProps={{ danger: true }}
        className="delete-dashboard-modal"
        centered
        destroyOnClose
        onOk={handleOnDeleteConfirm}
        onCancel={handleOnDeleteConfirmCancel}
      >
        {`Deleting the dashboard "${dashboard.name}" is permanent and can not be reverted.`}
      </Modal>
    </>
  );
};

export const EditDashboardModal = (props: Props) => {
  return !!props.dashboard && <DashboardModal {...props} dashboard={props.dashboard} />;
};
