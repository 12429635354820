import { RouteObject, redirect } from "react-router-dom";

import { SelfServiceExtractor } from "../../types/entities";

import AddInventoryJob from "./AddInventoryJob";
import AddDeliveryJob from "./AddDeliveryJob";
import DeliveryDetails from "./DeliveryDetails";
import JobDetails from "./JobDetails";
import JobsList from "./JobsList";

export const jobsRoutes: RouteObject = {
  children: [
    {
      index: true,
      element: <JobsList />,
    },
    {
      path: "add-inventory",
      loader: ({ params: { brandId } }) => redirect(`/brand/${brandId}/inventories/`),
    },
    {
      path: ":inventoryId",
      element: <JobDetails />,
    },
    {
      path: ":inventoryId/add-delivery",
      element: <AddDeliveryJob />,
    },
    {
      path: `add-inventory/${SelfServiceExtractor.EXTDCM_INVENTORY}`,
      element: <AddInventoryJob jobType={SelfServiceExtractor.EXTDCM_INVENTORY} />,
    },
    {
      path: `add-inventory/${SelfServiceExtractor.EXTFB_INVENTORY}`,
      element: <AddInventoryJob jobType={SelfServiceExtractor.EXTFB_INVENTORY} />,
    },
    {
      path: `add-inventory/${SelfServiceExtractor.EXTDV360_INVENTORY}`,
      element: <AddInventoryJob jobType={SelfServiceExtractor.EXTDV360_INVENTORY} />,
    },
    {
      path: `add-inventory/${SelfServiceExtractor.EXTTIKTOK_INVENTORY}`,
      element: <AddInventoryJob jobType={SelfServiceExtractor.EXTTIKTOK_INVENTORY} />,
    },
    {
      path: ":inventoryId/deliveries",
      loader: ({ params: { brandId, inventoryId } }) => redirect(`/brand/${brandId}/inventories/${inventoryId}`),
    },
    {
      path: ":inventoryId/deliveries/:deliveryId",
      element: <DeliveryDetails />,
    },
  ],
};
