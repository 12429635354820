import { CognitoUser } from "amazon-cognito-identity-js";

export function getUserNameFromCognito(user: CognitoUser): string {
  const session = user.getSignInUserSession();
  const idToken = session?.getIdToken();

  // sso user
  if (idToken?.payload?.given_name && idToken?.payload?.family_name) {
    return `${idToken.payload.given_name} ${idToken.payload.family_name}`;
  }

  // user/pass login
  if (idToken?.payload["cognito:username"]) {
    return idToken.payload["cognito:username"] as string;
  }

  if (user?.getUsername()) {
    return user?.getUsername();
  }

  return "";
}

export function getGivenNameFromCognito(user: CognitoUser): string | null {
  const session = user.getSignInUserSession();
  const idToken = session?.getIdToken();

  // sso user
  return (idToken?.payload?.given_name as string) ?? null;
}

export function getGroupsFromCognito(user: CognitoUser): string[] | null {
  const session = user.getSignInUserSession();
  const idToken = session?.getIdToken();

  // sso user
  return (idToken?.payload["cognito:groups"] as string[]) ?? null;
}
