import { SelfServiceExtractor } from "../types/entities";

const resourceTypeToLabelMap = {
  [SelfServiceExtractor.EXTDCM_INVENTORY]: "DCM",
  [SelfServiceExtractor.EXTFB_INVENTORY]: "FB",
  [SelfServiceExtractor.EXTDV360_INVENTORY]: "DV360",
  [SelfServiceExtractor.EXTTIKTOK_INVENTORY]: "TIKTOK",
};

export const resourceTypeToLabel = (resourceType: SelfServiceExtractor) => {
  return resourceTypeToLabelMap[resourceType];
};

export const encodeReportId = (reportId: string) => btoa(reportId);
export const decodeReportId = (reportId: string) => atob(reportId);

export const generateJobId = (jobName: string) => {
  return `${jobName.replace(/[^a-z0-9]/gi, "")}-${Date.now()}`;
};

export const camelToUpperCase = (text: string) =>
  text
    .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
    .replace(/([a-z])([0-9])/, "$1 $2")
    .replace(/^./, (name) => name.toUpperCase());

export const numberToShortKM = (value: number, mprecision = 0, kprecision = 0) => {
  let newValue;
  let suffix = "";

  if (value >= 1000000000) {
    newValue = Number.parseFloat((value / 1000000000).toString())
      .toFixed(mprecision)
      .replace("000000000", "");
    suffix = "B";
  } else if (value >= 1000000) {
    newValue = Number.parseFloat((value / 1000000).toString())
      .toFixed(mprecision)
      .replace("000000", "");
    suffix = "M";
  } else if (value >= 1000) {
    newValue = Number.parseFloat((value / 1000).toString())
      .toFixed(kprecision)
      .replace("000", "");
    suffix = "K";
  }

  return `${newValue ?? value.toFixed(2)}${suffix}`;
};

export const utilisationToValue = (utilisation: number) => (utilisation * 100).toFixed(0);

export const utilisationDisplayFormatter = (utilisation: number) => {
  const value = utilisationToValue(utilisation);

  return `${value}%`;
};
